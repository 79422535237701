import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'
import axios from "axios"; // empresa

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    usuario: '', // Datos del usuario
    refreshtoken: '',
    avatar: '', // para avatar de usuario
    empresa: [], // empresa array
    snackbar: { active: false, color: "", message: "", timeout: -1, icon: "" }
  },
  getters: {
    estaActivo: state => !!state.token,
    esADM: state => state.usuario.rol == "ADM",
    esSRRA: state => state.usuario.rol == "SRRA",
    esJBS: state => state.usuario.rol == "JBS",
    // captador empresa
    empresa: state => {
      return state.empresa;
    },
  },
  mutations: {
    //decodificamos payload/token datos del usuario
    setToken(state, payload) {
      state.token = payload;
      if (payload === '') {
        state.usuario = ''
      } else {
        state.usuario = decode(payload)
      }
    },

    setRefreshToken(state, refreshpayload) {
      state.refreshtoken = refreshpayload;
    },

    setAvatar(state, avatarpayload) {
      state.avatar = avatarpayload;
    },
    // empresa
    setEmpresa(state, empresa) {
      state.empresa = empresa
    },

    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
  },
  actions: {
    //Guardamos token, autologin y salir
    guardarToken({ commit }, payload) {
      localStorage.setItem("token", payload);
      commit("setToken", payload) //ejecutamos el payload=token
    },
    guardarRefreshToken({ commit }, refreshpayload) {
      localStorage.setItem("refreshtoken", refreshpayload);
      commit("setRefreshToken", refreshpayload)
    },
    guardarAvatar({ commit }, avatarpayload) {
      localStorage.setItem("avatar", avatarpayload);
      commit("setAvatar", avatarpayload)
    },
    // Verificamos si tiene token y si no salimos del sistema
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      let refreshtoken = localStorage.getItem("refreshtoken")
      let avatar = localStorage.getItem("avatar")
      if (token) {
        commit("setToken", token);
        commit("setRefreshToken", refreshtoken)
        commit("setAvatar", avatar)
        // Si actualizamos pagina
        this.dispatch("cargarEmpresa");
      } else {
        commit('setToken', '');
        commit("setRefreshToken", '');
        commit('setAvatar', '');
      }
    },

    salir({ commit }) {
      commit("setToken", '')
      commit("setRefreshToken", '')
      commit("setAvatar", '')
      localStorage.removeItem("token")
      localStorage.removeItem("refreshtoken")
      localStorage.removeItem("avatar")
      router.push({ name: 'login' })
    },

    // Accion para llamar la API 
    cargarEmpresa({ commit }) {
      axios
        .get("api/Empresas/Listar")
        .then(response => response.data)
        .then(empresa => {
          //console.log("Empresa Actualizado");
          commit('setEmpresa', empresa)
        })
        .catch(function (error) {
          //console.log("Empresa Erroneo");
        });
    },

    snackBar({ commit }, obj) {
      commit("setSnackbar", {
        active: true,
        color: obj.cl,
        message: obj.msg,
        timeout: 8000,
        icon: obj.cl == "success" ? "check_circle" : obj.cl
      });
    },
  },
  modules: {
  }
})
