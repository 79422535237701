import Vue from 'vue'
import VueRouter from 'vue-router'
import decode from 'jwt-decode'

import Dashboard from '../components/Dashboard.vue'
import Supervision from '../components/Supervision.vue'
import Empresa from '../components/Empresa.vue'
import Usuario from '../components/Usuario.vue'
import Agencia from '../components/Agencia.vue'
import Login from '../components/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      esADM: true,
      esSRRA: true,
      esJBS: true
    }
  },

  {
    path: '/supervision',
    name: 'supervision',
    component: Supervision,
    meta: {
      esADM: true,
      esSRRA: true,
      esJBS: true
    }
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: Empresa,
    meta: {
      esADM: true,
      esSRRA: false,
      esJBS: false
    }
  },
  {
    path: '/usuario',
    name: 'usuario',
    component: Usuario,
    meta: {
      esADM: true,
      esSRRA: true,
      esJBS: false
    }
  },
  {
    path: '/agencia',
    name: 'agencia',
    component: Agencia,
    meta: {
      esADM: true,
      esSRRA: true,
      esJBS: false
    }
  },
]

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")
  let usuario = token == null ? "" : decode(token)

  let ruta = false;
  let expr = usuario.rol;
  switch (expr) {
    case 'ADM':
      ruta = to.matched.some(record => record.meta.esADM)
      break;
    case 'SRRA':
      ruta = to.matched.some(record => record.meta.esSRRA)
      break;
    case 'JBS':
      ruta = to.matched.some(record => record.meta.esJBS)
      break;
  }

  if (ruta && !token) {
    next('login')
  } else if (!ruta && token) {
    next('dashboard')
  } else {
    next();
  }
})

export default router
