<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" v-if="estaActivo" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <!-- Dashboard -->
        <template v-if="esADM | esSRRA | esJBS">
          <v-list-item :to="{ name: 'dashboard' }">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Fin Dashboard -->

        <!-- Empresa -->
        <template v-if="esADM | esSRRA">
          <v-list-item :to="{ name: 'agencia' }">
            <v-list-item-icon>
              <v-icon>spoke</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Agencias</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Fin Empresa -->

        <!-- Supervisiones -->
        <template v-if="esADM | esSRRA | esJBS">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>auto_awesome_motion</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Supervisión</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'supervision' }">
              <v-list-item-content>
                <v-list-item-title>Listado</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Supervisiones -->

        <!-- Empresa -->
        <template v-if="esADM">
          <v-list-item :to="{ name: 'empresa' }">
            <v-list-item-icon>
              <v-icon>warehouse</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Empresa</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Fin Empresa -->

        <!-- Usuarios -->
        <template v-if="esADM || esSRRA">
          <v-list-item :to="{ name: 'usuario' }">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Usuarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Fin Usuarios -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark dense>
      <!-- Logo y titulo -->
      <v-toolbar-title style="width: 300px">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="estaActivo"></v-app-bar-nav-icon>
        <router-link to="/">
          <v-avatar tile size="30px" class="mr-1" large item>
            <v-img :src="require('./assets/logo.png')" alt="Vuetify" />
          </v-avatar>
        </router-link>
        <span class="font-weight-black">ontrol</span>
      </v-toolbar-title>
      <!-- Fin Logo y titulo -->

      <!-- oscuro -->
      <v-spacer />
      <v-btn icon dark hide-details @click="$vuetify.theme.dark = !$vuetify.theme.dark" v-if="estaActivo">
        <v-icon>contrast</v-icon>
      </v-btn>
      <!-- Fin oscuro -->

      <!-- Cerrar sesion -->
      <v-menu bottom left v-model="menuSesion" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="estaActivo">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="30px" item>
              <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="40px" item>
                  <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nombres }}</v-list-item-title>
                <v-list-item-subtitle> {{ usuario.correo }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ usuario.rol }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon>stream</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nomregion }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>stream</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nomterritorio }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn text small block color="error" @click="salir(), (menuSesion = false)">Cerrar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- Fin Cerrar sesion -->
    </v-app-bar>
    <!-- Contenido -->
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>
    <!-- Fin Contenido -->

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="snackbar.timeout"
      transition="slide-y-reverse-transition" right>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.message }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.active = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Fin Snackbar -->

    <!-- Pie de Pagina -->
    <v-footer padless v-if="!estaActivo">
      <!--       <v-row no-gutters class="primary text-center">
        <v-col cols="12">
          <div class="font-weight-regular text-h7 white--text mt-4">
            <a class="white--text" target="_blank" href="https://wa.me/+51930984158" style="text-decoration:none"><v-icon
                dark class="mb-1 mx-1">phone</v-icon>+51930984158</a>
            <a class="white--text" target="_blank" href="https://www.facebook.com/urpisoft"
              style="text-decoration:none"><v-icon dark class="mb-1 mx-1">facebook</v-icon></a>
          </div>
          <div class="font-weight-regular text-h7 white--text mb-4">
            <v-icon dark class="mb-1 mx-1">email</v-icon>efrain@urpisoft.pe
          </div>
        </v-col>
      </v-row> -->
      <v-card-text class="primary darken-2 text-center white--text">Copyright © <a class="white--text" target="_blank"
          href="https://urpisoft.pe" style="text-decoration:none">UrpiFact</a> {{ new
            Date().getFullYear() }}
        Todos los derechos reservados. v1.0.2</v-card-text>
    </v-footer>
    <!-- Fin Pie de Pagina -->
  </v-app>
</template>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: 'App',

  data: () => ({
    drawer: null,
    menuSesion: false,
  }),

  computed: {
    ...mapState(["usuario", "avatar", "snackbar"]),
    ...mapGetters(["estaActivo", "esADM", "esSRRA", "esJBS"]),
  },

  created() {
    this.autoLogin();
  },

  methods: {
    ...mapActions(["salir", "autoLogin", "cargarEmpresa"]),
  }
};

</script>
