<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <v-toolbar dark dense class="primary" flat>
                            <v-toolbar-title>Acceso al sistema</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form v-model="isValid">
                                <v-text-field label="Usuario" v-model.trim="email"
                                    :rules="[v => !!v || 'Email requerido', v => /.+@.+\..+/.test(v) || 'El email debe ser válido', v => (v || '').indexOf(' ') < 0 || 'No se permite espacios']"
                                    autofocus prepend-icon="person" right required></v-text-field>
                                <v-text-field label="Contraseña" v-model="password"
                                    :rules="[v => v.length >= 5 || 'La Contraseña debe tener mas de 5 caracteres']"
                                    prepend-icon="lock" :append-icon="mostrarpassword ? 'visibility' : 'visibility_off'"
                                    :type="mostrarpassword ? 'text' : 'password'"
                                    @click:append="mostrarpassword = !mostrarpassword" @keypress.enter="ingresar"
                                    required></v-text-field>
                                <!-- Mensaje -->
                                <template cols="12" v-if="mensajeLogin != ''">
                                    <v-alert text dense type="error">{{ mensajeLogin }}</v-alert>
                                </template>
                                <!-- Mensaje -->
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="py-0 pb-3 px-3">
                            <v-btn text @click="ingresar" block color="primary" :loading="desabilitar"
                                :disabled="desabilitar || !isValid">
                                Ingresar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            email: "",
            password: "",

            mensajeLogin: "",
            desabilitar: false,
            isValid: true,
            mostrarpassword: false,
            alerta: { snackbar: false, color: "", text: "" },
        };
    },

    methods: {
        ...mapActions(["guardarToken", "guardarRefreshToken", "guardarAvatar", "cargarEmpresa"]),

        ingresar() {
            let me = this;
            me.desabilitar = true;
            me.error = null;

            axios.post("api/Usuarios/Login", {
                email: me.email,
                password: me.password,
            })
                .then((respuesta) => {
                    me.desabilitar = false;
                    return respuesta.data;
                })
                .then((data) => {
                    const token = data.token;
                    const refreshtoken = data.refreshtoken;
                    const avatar = data.avatar;
                    me.guardarToken(token);
                    me.guardarRefreshToken(refreshtoken);
                    me.guardarAvatar(avatar);
                    me.cargarEmpresa();
                    me.$router.push({ name: "dashboard" });
                })
                .catch((err) => {
                    me.desabilitar = false;
                    if (err.response.status == 400) {
                        me.mensajeLogin = "No es un email válido";
                    } else if (err.response.status == 404) {
                        me.mensajeLogin =
                            "No existe el usuario ó sus datos son incorrectos";
                    } else {
                        me.mensajeLogin = "Ocurrió un error";
                    }
                    me.limpiarAlertaError();
                    //console.log(err)
                });
        },

        limpiarAlertaError() {
            setTimeout(() => {
                this.mensajeLogin = "";
            }, 4000);
        },
    },
};
</script>

