<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <v-row justify="center" class="text-center mt-4">
          <v-col cols="12">
            <h1 class="display-1 primary--text">INDICADORES</h1>
          </v-col>
          <v-col cols="5" sm="2">
            <v-select dense v-model="ano" :items="anos" label="Año" @change="supervisionAgencia" hide-details>
            </v-select>
          </v-col>
          <v-col cols="5" sm="3">
            <v-select dense v-model="idagencia" :items="agencias" label="Agencia" @change="cargarChart()" hide-details>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mt-6" justify="center">
          <v-col cols="12" md="8">
            <v-card class="elevation-5">
              <v-card-text>
                <div>
                  <canvas id="barSupervision"></canvas>
                </div>
              </v-card-text>
              <v-divider />
              <v-card-actions class="justify-center"><v-btn small icon
                  @click="exportChart"><v-icon>assessment</v-icon></v-btn>Planilla de Supervisión</v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
import Chart from "chart.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      supervisiones: [],
      meses: [],
      notasSRRA: [],
      notasJBS: [],

      barSupervision: null,

      idagencia: "",
      agencias: [],
      ano: parseInt(moment().format("YYYY")),
      anos: [
        { text: 2023 },
        { text: 2024 },
        { text: 2025 },
      ],
    };
  },

  computed: {
    ...mapState(["usuario"]),
  },

  created() {
    this.select();
    //this.supervisionAgencia();
  },

  methods: {

    select() {
      let me = this;

      var agenciasArray = [];
      if (me.usuario.rvisita == 'SRRA') {
        axios.get("api/Agencias/SelectAgenciaSRRA", {
          params: {
            idregion: me.usuario.idregion
          },
        })
          .then(function (response) {
            agenciasArray = response.data;
            agenciasArray.map(function (x) {
              me.agencias.push({ text: x.dscagencia, value: x.idagencia });
            });
            me.idagencia = me.agencias[0].value;
            me.cargarChart();
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Agencias/SelectAgenciaJBS", {
          params: {
            idregion: me.usuario.idregion,
            idusuario: me.usuario.idusuario
          },
        })
          .then(function (response) {
            agenciasArray = response.data;
            agenciasArray.map(function (x) {
              me.agencias.push({ text: x.dscagencia, value: x.idagencia });
            });
            me.idagencia = me.agencias[0].value;
            me.cargarChart();
            //console.log(me.agencias[0].value)
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    exportChart() {
      const canvas = document.getElementById('barSupervision');
      const base64Image = canvas.toDataURL('image/png');

      // Crear un enlace temporal y descargar la imagen
      const link = document.createElement('a');
      link.href = base64Image;
      link.download = 'chart.png';
      link.click();
    },

    cargarChart() {
      if (this.barSupervision) {
        this.barSupervision.destroy();
      }
      this.supervisionAgencia();
    },

    supervisionAgencia() {
      let me = this;
      me.supervisiones = [];
      me.meses = [];
      me.notasSRRA = [];
      me.notasJBS = [];

      axios.get("api/Plsupervisions/SupervisionAgencia", {
        params: {
          idagencia: me.idagencia,
          ano: me.ano
        }
      })
        .then(function (response) {
          me.supervisiones = response.data;
          me.cargarGraficoBar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    cargarGraficoBar() {
      let me = this;

      for (var i = 0; i < me.supervisiones.length; i++) {
        let nommes = "";
        switch (me.supervisiones[i].mes) {
          case 1:
            nommes = "Enero";
            break;
          case 2:
            nommes = "Febrero";
            break;
          case 3:
            nommes = "Marzo";
            break;
          case 4:
            nommes = "Abril";
            break;
          case 5:
            nommes = "Mayo";
            break;
          case 6:
            nommes = "Junio";
            break;
          case 7:
            nommes = "Julio";
            break;
          case 8:
            nommes = "Agosto";
            break;
          case 9:
            nommes = "Setiembre";
            break;
          case 10:
            nommes = "Octubre";
            break;
          case 11:
            nommes = "Noviembre";
            break;
          case 12:
            nommes = "Diciembre";
            break;
        }

        me.meses.push(nommes);
        me.notasSRRA.push(me.supervisiones[i].notasrra);
        me.notasJBS.push(me.supervisiones[i].notajbs);
      }

      var ctx = document.getElementById("barSupervision").getContext('2d');
      me.barSupervision = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.meses,
          datasets: [
            {
              label: 'JBS',
              data: me.notasJBS,
              backgroundColor: "#8BC34A",
            },
            {
              label: 'SRRA',
              data: me.notasSRRA,
              backgroundColor: "#FF9800",
            },
          ],
        },
      });
    },
  }
}
</script>