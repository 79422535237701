<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Listado supAgencias -->
        <v-data-table :loading="loading" :headers="headersAg" :items="supAgencias" :items-per-page="-1" dense
          class="elevation-3" v-if="verSupervision == 0" hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">SUPERVISIONES</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- busqueda -->
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscarAgencia" append-icon="search" dense
                label="Búsqueda" @keyup="listarSupervisionAg()" single-line hide-details></v-text-field>
              <v-spacer />
              <!-- Fin busqueda -->
            </v-toolbar>
          </template>

          <!-- opciones-->
          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="info" @click="mostrarSupervision(item)">visibility</v-icon>
            </v-btn>
          </template>
          <!-- Fin opciones-->
        </v-data-table>
        <!-- Fin Listado supAgencias -->

        <!-- Modal Supervisiones -->
        <v-card v-if="verSupervision == 1" class="elevation-3">
          <v-toolbar dark dense class="info" flat>
            <v-btn small icon @click="crearPDF()" class="ml-1">
              <v-icon>print</v-icon>
            </v-btn>
            <v-toolbar-title class="hidden-sm-and-down">Supervisiones Ag. {{ dscagencia }}</v-toolbar-title>
            <v-spacer />
            <template>
              <v-col cols="8" md="4" sm="5">
                <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                  offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense solo-inverted v-model="dateRangeText" readonly v-on="on" hide-details
                      style="max-width: 240px;" append-icon="update" @click:append="listarPlSup()"></v-text-field>
                  </template>
                  <v-date-picker v-model="dates"
                    @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listarPlSup()"
                    range></v-date-picker>
                </v-menu>
              </v-col>
            </template>
            <v-spacer />
            <v-btn small icon @click="newSupDet" :loading="desabilitar" :disabled="desabilitar || !isValid" class="mr-1">
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn small icon @click="closeSupervision()" class="mr-1">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :loading="loading" :headers="headersPlSup" :items="supervisiones" :items-per-page="15"
            item-key="idplsupervision" show-expand dense>
            <template v-slot:top>
              <!-- Modal Supervision -->
              <v-dialog v-model="dialogSupDet" max-width="1280px" persistent>
                <v-form v-model="isValidDet">
                  <v-card>
                    <v-toolbar dark dense class="secondary" flat>
                      <v-btn small icon @click="crearXLSX" :disabled="desabilitar" class="ml-1">
                        <v-icon>description</v-icon>
                      </v-btn>
                      <v-toolbar-title class="hidden-sm-and-down">{{ formTitleSup }} {{ planilla }}
                      </v-toolbar-title>
                      <v-spacer />
                      <strong>({{ calcularResultadoFinal() }}) {{ obtenerEvaluacion(calcularResultadoFinal()) }}</strong>
                      <v-spacer />
                      <v-select dense solo-inverted :items="numVisitas" v-model="numvisita" label="Visita"
                        style="max-width: 85px;" :rules="[v => !!v || 'Requerido']" class="mr-1" required
                        hide-details></v-select>
                      <v-text-field dense solo-inverted v-model="jira" label="Caso Jira" maxlength="12"
                        style="max-width: 130px;" :rules="[Rules.unespacio]" class="mr-1" v-uppercase hide-details>
                      </v-text-field>

                      <v-btn small icon @click="savePlsupervision" :loading="desabilitar"
                        :disabled="desabilitar || !isValidDet" v-if="elimod == 'S' | usuario.rol == 'ADM'" class="mr-1">
                        <v-icon>save</v-icon>
                      </v-btn>
                      <v-btn small icon @click="closeSupDet" class="mr-1">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <!-- Modal Detalle -->
                    <v-data-table :loading="loadingdet" :headers="headersTitDet" :items="detallesPlSup"
                      item-key="idsplanilla" show-expand hide-default-footer dense>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-data-table :headers="headersSupDet" :items="item.detalles" item-key="iddplanilla"
                            hide-default-footer dense>
                            <template v-slot:item.estado="{ item }">
                              <v-select dense :items="estados" v-model="item.estado"
                                @change="item.estado == 'No Cumple' ? (item.nota = 0) : (item.nota = item.dnota)" required
                                hide-details></v-select>
                            </template>
                            <template v-slot:item.comentario="{ item }">
                              <v-text-field dense type="text" v-model="item.comentario" maxlength="30" hide-details
                                onClick="this.select()"></v-text-field>
                            </template>
                            <template v-slot:item.planaccion="{ item }">
                              <v-text-field dense type="text" v-model="item.planaccion" maxlength="20" hide-details
                                onClick="this.select()"></v-text-field>
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                      <template v-slot:item.dsctsplanilla="{ item }">
                        {{ item.dsctplanilla + " > " + item.dscsplanilla }}
                      </template>
                      <template v-slot:item.rnota="{ item }">
                        {{ sumarNotasGrupo(item.detalles).toFixed(2) }}
                      </template>
                      <template v-slot:item.rspeso="{ item }">
                        {{ multiplicarPorPeso(sumarNotasGrupo(item.detalles), item.speso).toFixed(2) }}
                      </template>
                    </v-data-table>
                    <!-- Modal Fin Detalle -->
                  </v-card>
                </v-form>
              </v-dialog>
              <!-- Fin Modal Supervision -->
            </template>
            <!-- Expandido -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Supervisor:</strong>
                {{ item.nomsupervisor }}
              </td>
            </template>
            <!-- Expandido -->
            <!-- Formateamos -->
            <template v-slot:item.califnota="{ item }">
              <template v-if="item.calificacion == 'SatisfactorioSO'">
                <strong class="bluedarken--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
              <template v-else-if="item.calificacion == 'Satisfactorio'">
                <strong class="greendarken--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
              <template v-else-if="item.calificacion == 'Aceptable'">
                <strong class="yellowdarken--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
              <template v-else-if="item.calificacion == 'Regular'">
                <strong class="orangedarken--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
              <template v-else-if="item.calificacion == 'Deficiente'">
                <strong class="reddarken--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
              <template v-else>
                <strong class="grey--text">({{ item.notafinal.toFixed(2) }}){{ item.calificacion }}</strong>
              </template>
            </template>
            <template v-slot:item.fechamodificado="{ item }">
              <span>{{ item.fechamodificado | formatearFechaHm }}</span>
            </template>
            <template v-slot:item.fechacreado="{ item }">
              <span>{{ item.fechacreado | formatearFechaHm }}</span>
            </template>
            <!-- Fin Formateamos -->

            <!-- Opciones-->
            <template v-slot:item.opciones="{ item }">
              <v-btn x-small icon>
                <v-icon color="secondary" @click="editItemSupDet(item)">edit</v-icon>
              </v-btn>
              <v-btn x-small icon v-if="item.elimod == 'S' | usuario.rol == 'ADM'">
                <v-icon color="error" @click="deleteItemSupDet(item)">delete_forever</v-icon>
              </v-btn>
            </template>
            <!-- Fin Opciones-->

          </v-data-table>
        </v-card>
        <!-- Fin Modal Supervisiones -->

      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import ExcelJS from 'exceljs';
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      supAgencias: [],
      supervisiones: [],
      detallesPlSup: [],

      dialogSupDet: false,
      headersAg: [
        { text: "Cod.", value: "codigo", sortable: false, width: 8 },
        { text: "Agencias", value: "dscagencia", sortable: false },
        { text: "JBS", value: "nomjbs", sortable: false },
        //{ text: "Dirección", value: "direccion", sortable: false },
        //{ text: "Estado", value: "condicion", sortable: false },
        { text: "Opc.", value: "opciones", sortable: false, width: 75 },
      ],

      headersPlSup: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "Visita", value: "numvisita", sortable: false, width: 6 },
        { text: "Planilla", value: "planilla", sortable: false, width: 20 },
        { text: "Calificación", value: "califnota", sortable: false, width: 150 },
        { text: "Caso JIRA", value: "jira", sortable: false },
        { text: "Fecha Actualizado", value: "fechamodificado", sortable: false, width: 150 },
        { text: "Fecha Visita", value: "fechacreado", sortable: false, width: 150 },
        { text: "Opción", value: "opciones", sortable: false, width: 75 },
      ],

      headersTitDet: [
        { text: "Planilla de Supervisión", value: "dsctsplanilla", sortable: false },
        { text: "Nota", value: "rnota", sortable: false, width: 90 },
        //{ text: "Peso", value: "rspeso", sortable: false, width: 90 },
        //{ text: "Tpeso", value: "tpeso", sortable: false, width: 90 },
      ],

      headersSupDet: [
        { text: "Descripción", value: "dscdplanilla", sortable: false },
        { text: "Estado", value: "estado", sortable: false, width: 150 },
        { text: "Comentario", value: "comentario", sortable: false, width: 400 },
        { text: "Plan/Acción", value: "planaccion", sortable: false, width: 250 },
        //{ text: "Nota", value: "nota", sortable: false, width: 80 },
        //{ text: "Speso", value: "speso", sortable: false, width: 80 },
        //{ text: "TPeso", value: "tpeso", sortable: false, width: 80 },
      ],

      loading: false,
      loadingdet: false,

      buscarAgencia: "",

      idplsupervision: "",
      calificacion: "",
      jira: "",
      numvisita: "",
      planilla: "",
      elimod: "S",

      nomjbs: "",

      editedIndexDet: -1,
      verSupervision: 0,

      desabilitar: false,
      isValid: true,
      isValidDet: true,

      numVisitas: [
        { text: 1 },
        { text: 2 },
        { text: 3 },
        { text: 4 },
        { text: 5 },
        { text: 6 },
        { text: 7 },
        { text: 8 },
        { text: 9 },
        { text: 10 },
        { text: 11 },
        { text: 12 }
      ],

      estados: [
        { text: "Si Cumple" },
        { text: "No Cumple" }
      ],

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],

      menuFechaBuscar: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "empresa"]),

    formTitleSup() {
      return this.editedIndexDet === -1 ? "Nueva Supervisión" : "Actualizar Supervisión";
    },

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

  },

  created() {
    this.listarSupervisionAg();
  },

  methods: {
    ...mapActions(["snackBar"]),

    //#region Calculos Planilla
    obtenerEvaluacion(nota) {
      if (nota === 0) {
        return "";
      } else if (nota >= 100) {
        return "SatisfactorioSO";
      } else if (nota >= 90) {
        return "Satisfactorio";
      } else if (nota >= 80) {
        return "Aceptable";
      } else if (nota >= 70) {
        return "Regular";
      } else {
        return "Deficiente";
      }
    },

    sumarNotasGrupo(detalles) {
      let suma = 0;
      for (let i = 0; i < detalles.length; i++) {
        suma += detalles[i].nota;
      }
      return suma;
    },

    multiplicarPorPeso(resultadoGrupo, peso) {
      return resultadoGrupo * (peso / 100);
    },

    sumarNotasGrupo(detalles) {
      let sumaNotas = 0;

      detalles.forEach((detalle) => {
        const nota = detalle.nota;
        sumaNotas += nota;
      });

      return sumaNotas;
    },

    calcularResultadoFinal() {
      let resultadoFinal = 0;

      this.detallesPlSup.forEach((item) => {
        const notasGrupo = this.sumarNotasGrupo(item.detalles);
        const speso = item.speso;
        const tpeso = item.tpeso;

        const resultadoGrupo = notasGrupo * speso / 100;
        const resultadoPeso = resultadoGrupo * tpeso / 100;

        resultadoFinal += resultadoPeso;
      });

      return resultadoFinal.toFixed(2);
    },
    //#endregion

    //#region Supervisiones
    listarSupervisionAg() {
      let me = this;
      me.loading = true;

      if (me.usuario.rvisita == 'SRRA') {
        axios.get("api/Agencias/ListarSupAgencia", {
          params: {
            buscar: me.buscarAgencia == "" ? "-" : me.buscarAgencia,
            idregion: me.usuario.idregion
          },
        })
          .then(function (response) {
            me.supAgencias = response.data;
            me.loading = false;
          })
          .catch(function (error) {
          });
      } else {
        axios.get("api/Agencias/ListarSupAgenciaJbs", {
          params: {
            buscar: me.buscarAgencia == "" ? "-" : me.buscarAgencia,
            idregion: me.usuario.idregion,
            idusuario: me.usuario.idusuario
          },
        })
          .then(function (response) {
            me.supAgencias = response.data;
            me.loading = false;
          })
          .catch(function (error) {
          });
      }
    },

    listarPlSup() {
      let me = this;
      me.loading = true;

      axios.get("api/Plsupervisions/ListarPlsupervision", {
        params: {
          idagencia: me.idagencia,
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.supervisiones = response.data;
          me.loading = false;
        })
        .catch(function (error) {
        });
    },

    mostrarSupervision(item) {
      this.idagencia = item.idagencia;
      this.dscagencia = item.dscagencia;
      this.nomjbs = item.nomjbs;
      this.verSupervision = 1;
      this.listarPlSup();
    },

    closeSupervision() {
      this.idagencia = "";
      this.dscagencia = "";
      this.nomjbs = "";
      this.supervisiones = [];
      this.verSupervision = 0;
    },


    savePlsupervision() {
      let me = this;
      me.desabilitar = true;
      // {{ obtenerEvaluacion(calcularResultadoFinal()) }} ({{ calcularResultadoFinal() }})
      if (this.editedIndexDet > -1) {
        axios.put("api/Plsupervisions/Actualizar", {
          idplsupervision: me.idplsupervision,
          idagencia: me.idagencia,
          idusuario: me.usuario.idusuario,
          numvisita: me.numvisita,
          planilla: me.usuario.rvisita,
          calificacion: me.obtenerEvaluacion(me.calcularResultadoFinal()),
          notafinal: me.calcularResultadoFinal(),
          jira: me.jira,
          // detalles
          //Detplsupervisions: me.detallesPlSup
          Detplsupervisions: me.detallesPlSup.flatMap(item => [...item.detalles])
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.closeSupDet();
            me.listarPlSup();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listarPlSup();
          });
      } else {
        axios.post("api/Plsupervisions/Crear", {
          idagencia: me.idagencia,
          idusuario: me.usuario.idusuario,
          numvisita: me.numvisita,
          planilla: me.usuario.rvisita,
          calificacion: me.obtenerEvaluacion(me.calcularResultadoFinal()),
          notafinal: me.calcularResultadoFinal(),
          jira: me.jira,
          // detalles
          //Detplsupervisions: me.detallesPlSup
          Detplsupervisions: me.detallesPlSup.flatMap(item => [...item.detalles])
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.closeSupDet();
            me.listarPlSup();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listarPlSup();
          });
      }
    },

    editItemSupDet(item) {
      this.idplsupervision = item.idplsupervision;
      this.numvisita = item.numvisita;
      this.planilla = item.planilla;
      this.jira = item.jira;
      this.elimod = item.elimod;
      this.fechacreado = item.fechacreado;
      this.calificacion = item.calificacion;
      this.notafinal = item.notafinal;

      this.editedIndexDet = 1;
      this.dialogSupDet = true;
      this.listarDetalleSup(item.idplsupervision);
    },

    newSupDet() {
      this.planilla = this.usuario.rvisita;
      this.editedIndexDet = -1;
      this.dialogSupDet = true;
      this.listarDplanilla();
    },

    closeSupDet() {
      this.numvisita = "";
      this.planilla = "";
      this.jira = "";
      this.elimod = "S";
      this.fechacreado = "";
      this.calificacion = "";
      this.notafinal = "";

      this.detallesPlSup = [];

      this.editedIndexDet = -1;
      this.dialogSupDet = false;
      this.loadingdet = false;
    },

    deleteItemSupDet(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la supervisión " + item.numvisita + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Plsupervisions/Eliminar/" + item.idplsupervision)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            me.listarPlSup();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    listarDplanilla() {
      let me = this;
      var detallesArray = [];
      me.loadingdet = true;

      let url = "";
      if (me.usuario.rvisita == "JBS") {
        url = "api/Dplanillas/ListarDplanillaJbs";
      } else {
        url = "api/Dplanillas/ListarDplanillaSrra"
      }

      axios.get(url)
        .then(function (response) {
          detallesArray = response.data;

          const gruposPlSup = [];
          const gruposMap = new Map();

          detallesArray.forEach(function (detalle) {
            const idsplanilla = detalle.idsplanilla;

            if (!gruposMap.has(idsplanilla)) {
              gruposMap.set(idsplanilla, {
                idsplanilla: idsplanilla,
                idtplanilla: detalle.idtplanilla,
                speso: detalle.speso,
                tpeso: detalle.tpeso,
                dscsplanilla: detalle.dscsplanilla,
                dsctplanilla: detalle.dsctplanilla,
                detalles: []
              });
            }

            const grupo = gruposMap.get(idsplanilla);
            grupo.detalles.push({
              iddetplsupervision: 0,
              idplsupervision: 0,
              iddplanilla: detalle.iddplanilla,
              idsplanilla: detalle.idsplanilla,
              idtplanilla: detalle.idtplanilla,
              dscsplanilla: detalle.dscsplanilla,
              dscdplanilla: detalle.dscdplanilla,
              estado: "Si Cumple",
              comentario: "",
              planaccion: "",
              nota: detalle.dnota,
              dnota: detalle.dnota,
              speso: detalle.speso,
              tpeso: detalle.tpeso
            });
          });

          gruposMap.forEach(function (grupo) {
            gruposPlSup.push(grupo);
          });
          me.detallesPlSup = gruposPlSup
          me.loadingdet = false;
        })
        .catch(function (error) {
          //console.log(error);
        });

    },

    listarDetalleSup(id) {
      let me = this;
      var detallesArray = [];
      me.loadingdet = true;

      axios.get("api/Plsupervisions/ListarDetPlSupervision/" + id)
        .then(function (response) {
          detallesArray = response.data;

          const gruposPlSup = [];
          const gruposMap = new Map();

          detallesArray.forEach(function (detalle) {
            const idsplanilla = detalle.idsplanilla;

            if (!gruposMap.has(idsplanilla)) {
              gruposMap.set(idsplanilla, {
                idsplanilla: idsplanilla,
                idtplanilla: detalle.idtplanilla,
                speso: detalle.speso,
                tpeso: detalle.tpeso,
                dscsplanilla: detalle.dscsplanilla,
                dsctplanilla: detalle.dsctplanilla,
                detalles: []
              });
            }

            const grupo = gruposMap.get(idsplanilla);
            grupo.detalles.push({
              iddetplsupervision: detalle.iddetplsupervision,
              idplsupervision: detalle.idplsupervision,
              iddplanilla: detalle.iddplanilla,
              idsplanilla: detalle.idsplanilla,
              idtplanilla: detalle.idtplanilla,
              dscsplanilla: detalle.dscsplanilla,
              dscdplanilla: detalle.dscdplanilla,
              estado: detalle.estado,
              comentario: detalle.comentario,
              planaccion: detalle.planaccion,
              nota: detalle.nota,
              dnota: detalle.dnota,
              speso: detalle.speso,
              tpeso: detalle.tpeso,
            });
          });

          gruposMap.forEach(function (grupo) {
            gruposPlSup.push(grupo);
          });
          me.detallesPlSup = gruposPlSup
          me.loadingdet = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion

    crearPDF() {
      var columns = [
        { header: "Visita", dataKey: "numvisita" },
        { header: "Planilla", dataKey: "planilla" },
        { header: "Calificación", dataKey: "calificacion" },
        { header: "Nota", dataKey: "notafinal" },
        { header: "Caso JIRA", dataKey: "jira" },
        { header: "Fecha Actualizado", dataKey: "fechamodificado" },
        { header: "Fecha Visita", dataKey: "fechacreado" },
        { header: "Supervisor", dataKey: "nomsupervisor" },
      ];
      var body = [];

      this.supervisiones.map(function (x) {
        body.push({
          numvisita: x.numvisita,
          planilla: x.planilla,
          calificacion: x.calificacion,
          notafinal: x.notafinal.toFixed(2),
          jira: x.jira,
          fechamodificado: moment(x.fechamodificado).format("DD/MM/YYYY HH:mm"),
          fechacreado: moment(x.fechacreado).format("DD/MM/YYYY HH:mm"),
          nomsupervisor: x.nomsupervisor
        });
      });

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("SUPERVISIONES AGENCIA " + this.dscagencia, doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      // Configurando hoja
      var imgData = "data:image/png;base64," + this.empresa[0].logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          numvisita: { cellWidth: 40, halign: "center" },
          planilla: { cellWidth: 50, halign: "center" },
          calificacion: { cellWidth: "auto" },
          notafinal: { cellWidth: 40, halign: "right" },
          jira: { cellWidth: 50 },
          fechamodificado: { cellWidth: 80, halign: "center" },
          fechacreado: { cellWidth: 80, halign: "center" },
          usuario: { cellWidth: 110 },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("Supervision Ag. " + this.dscagencia + ".pdf");
    },

    async crearXLSX() {
      // Crear un nuevo libro de Excel
      const workbook = new ExcelJS.Workbook();

      // Definir el nombre de la hoja
      const sheetName = 'Supervision';

      // Crear los datos para la hoja
      const worksheetData = [['PLANILLA DE SUPERVISION - ' + (this.planilla == 'JBS' ? 'JEFE DE BANCA Y SERVICIO' : (this.planilla == 'SRRA' | this.planilla == 'ADM') ? 'SUPERVISOR REGIONAL' : ' SIN PLANILLA'), '', '', '', '', '']];
      const datos = ['AGENCIA: ' + this.dscagencia, '', 'JBS: ' + this.nomjbs, 'FECHA: ' + moment(this.fechacreado).format("DD/MM/YYYY")];
      worksheetData.push(datos);

      // Variable auxiliar para realizar un seguimiento de los valores de idtplanilla únicos
      const uniqueIdtPlanilla = new Set();

      // Crear un arreglo para almacenar las subcabeceras fuera del grupo
      const subcabecerasFueraGrupo = [['', 'NOTA', '', '', '', 'TGC', '', '', '']];

      // Crear un objeto para almacenar las sumas por idtplanilla
      const sumaNotasPorIdtPlanilla = {};
      // Iterar sobre los detalles
      this.detallesPlSup.forEach(detalle => {
        const subcabecera = [detalle.dsctplanilla, '', '', '', '', 'T', detalle.idtplanilla, '', detalle.tpeso];

        const subcabeceraFuera = [detalle.dsctplanilla, '', '', '', '', 'TG', detalle.idtplanilla, '', detalle.tpeso];

        // Verificar si el valor de idtplanilla ya se ha agregado antes
        if (!uniqueIdtPlanilla.has(detalle.idtplanilla)) {
          worksheetData.push(subcabecera);
          subcabecerasFueraGrupo.push(subcabeceraFuera);
          uniqueIdtPlanilla.add(detalle.idtplanilla);
        }

        worksheetData.push([detalle.dscsplanilla, 'Estado', 'Comentarios', 'Plan de Accion', 'NOTA', 'S', detalle.idsplanilla, detalle.speso, '']);

        // Realizar la suma de las notas por idtplanilla
        if (detalle.detalles.length > 0) {
          const notasGrupo = detalle.detalles.map(item => item.nota);
          const sumaNotas = notasGrupo.reduce((sum, nota) => sum + nota * detalle.speso / 100, 0);
          if (sumaNotasPorIdtPlanilla[detalle.idtplanilla]) {
            sumaNotasPorIdtPlanilla[detalle.idtplanilla] += sumaNotas;
          } else {
            sumaNotasPorIdtPlanilla[detalle.idtplanilla] = sumaNotas;
          }
        }

        detalle.detalles.forEach(item => {
          const row = [item.dscdplanilla, item.estado, item.comentario, item.planaccion, item.nota];
          worksheetData.push(row);
        });

        // Agregar espacio en blanco después de cada grupo
        worksheetData.push([]);
      });

      // Actualizar el valor de la columna "NOTA" en subcabecerasFueraGrupo
      subcabecerasFueraGrupo.forEach(subcabeceraFuera => {
        if (sumaNotasPorIdtPlanilla.hasOwnProperty(subcabeceraFuera[6])) {
          subcabeceraFuera[1] = sumaNotasPorIdtPlanilla[subcabeceraFuera[6]];
        }
      });

      // Agregar las subcabeceras fuera del grupo al inicio de la lista worksheetData
      worksheetData.push(...subcabecerasFueraGrupo);
      worksheetData.push(['CALIFICACION FINAL', this.notafinal, '', '', '', 'TGP', '', '', '']);
      worksheetData.push(['', this.calificacion, '', '', '', 'TGP', '', '', '']);

      // Agregar una nueva hoja de cálculo al libro
      const worksheet = workbook.addWorksheet(sheetName);

      // Crear una imagen de base64
      const Base64 = 'data:image/png;base64,' + this.empresa[0].logo;
      const logoImage = workbook.addImage({
        base64: Base64,
        extension: 'jpeg',
      });

      // Agregar la imagen a la hoja de cálculo
      worksheet.addImage(logoImage, {
        ext: { width: 80, height: 40 },
      });

      // Establecer los datos en la hoja de cálculo
      worksheet.addRows(worksheetData);

      // Ajustar el alto de la fila 1
      worksheet.getRow(1).height = 30;

      // Combinar las celdas A hasta E en la primera fila
      worksheet.mergeCells('A1:E1');
      // Establecer estilo para el título
      const titleCell = worksheet.getCell('A1');
      titleCell.alignment = { vertical: 'middle', horizontal: 'center' };
      titleCell.font = { bold: true, size: 16 };

      // Establecer estilo para la fila 2
      const row2 = worksheet.getRow(2);
      row2.font = { bold: true, size: 12 };

      // Recorrer las filas y aplicar el formato a las que tienen el valor "T" en la celda F
      worksheet.eachRow((row, rowNumber) => {
        const cellF = row.getCell('F');
        if (cellF.value === 'T') {
          for (let col = 1; col <= 1; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'F5BE15' } // Amarillo oscuro
            };
            cell.font = {
              bold: true
            };
          }
          for (let col = 2; col <= 5; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '8ECF64' } // Verde
            };
            cell.font = {
              bold: true
            };
          }
        }
        else if (cellF.value === 'S') {
          for (let col = 1; col <= 5; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFEA6' } // Amarillo claro
            };
            cell.font = {
              bold: true
            };
          }
        }
        else if (cellF.value === 'TGC') {
          for (let col = 2; col <= 2; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'F5BE15' } // Amarillo oscuro
            };
            cell.font = {
              bold: true
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear en el centro
          }
        }
        else if (cellF.value === 'TG') {
          for (let col = 1; col <= 2; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'F5BE15' } // Amarillo oscuro
            };
            cell.font = {
              bold: true
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear en el centro
          }
        }
        else if (cellF.value === 'TGP') {
          for (let col = 1; col <= 1; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '8ECF64' } // Verde
            };
            cell.font = {
              bold: true
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear en el centro
          }
          for (let col = 2; col <= 2; col++) {
            const cell = row.getCell(col);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'B7B7B7' } // Plomo
            };
            cell.font = {
              bold: true
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear en el centro
          }
        }
      });

      // Ajustar el ancho de la columna
      worksheet.getColumn('A').width = 40;
      worksheet.getColumn('B').width = 12;
      worksheet.getColumn('C').width = 35;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 6;

      // Ocultar las columnas consecutivas F - I
      for (let col = 6; col <= 9; col++) {
        worksheet.getColumn(col).hidden = true;
      }

      // Ocultar las líneas de división de la hoja de cálculo
      worksheet.views = [
        {
          showGridLines: false
        }
      ];

      // Guardar el archivo de Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'REG-MIB-515 PLANILLA DE SUPERVISION ' + this.planilla + ' V9.1.xlsx';
      link.click();
      URL.revokeObjectURL(url);
    }

  },
};
</script>