<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado de agencia -->
        <v-data-table :loading="loading" :headers="headers" :items="agencias" :items-per-page="15" dense
          class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">AGENCIAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <v-select dense v-model="idregion" :items="regiones" label="Región > Territorio" style="max-width: 250px;"
                @change="listar" :rules="[v => !!v || 'La región es requerido']" hide-details>
              </v-select>
              <v-spacer />
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscarAgencia" append-icon="search" dense label="Búsqueda"
                @keyup="listar()" single-line hide-details></v-text-field>
              <v-spacer />
              <v-dialog v-model="dialog" max-width="400px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn text color="primary" v-on="on" fab small>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="3">
                          <v-text-field dense v-model.trim="codigo" label="Código"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1) || 'Nombre debe tener mas de 1 caracter']"
                            maxlength="3" @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field dense v-model.trim="dscagencia" label="Agencia"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 30) || 'Nombre debe tener entre 3 a 30 caracteres', Rules.unespacio]"
                            maxlength="30" prepend-inner-icon="store" v-uppercase clearable hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="direccion" label="Direccion"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 60) || 'Dirección debe tener entre 3 a 60 caracteres', Rules.unespacio]"
                            maxlength="60" prepend-inner-icon="place" v-uppercase clearable hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscarUsuario"
                            v-model="idusuario" :items="usuarios" label="Jefe de Banca y Servicio"
                            :rules="[v => !!v || 'Usuario requerido']" prepend-inner-icon="pin_drop" clearable
                            hide-details></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn text color="error" @click="close" small>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="guardar" :loading="desabilitar"
                      :disabled="desabilitar || !isValid" small>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn x-small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn x-small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- Fin Listado de agencia -->
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      agencias: [],
      dialog: false,
      headers: [
        { text: "Cod.", value: "codigo", sortable: false },
        { text: "Agencia", value: "dscagencia", sortable: false },
        { text: "Dirección", value: "direccion", sortable: false },
        { text: "Jefe de Banca y Servicio", value: "nomjbs", sortable: false },
        { text: "Opción", value: "opciones", sortable: false, width: 90 },
      ],
      buscarAgencia: "",
      buscarUsuario: "",
      editedIndex: -1,

      idagencia: "",
      idregion: "",
      regiones: [],
      idusuario: "",
      usuarios: [],
      dscagencia: "",
      codigo: "",
      direccion: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",

      desabilitar: false,

      isValid: true,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario"]),

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Agencia" : "Actualizar Agencia";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.idregion = parseInt(this.usuario.idregion);
    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar agencia */
    listar() {
      let me = this;

      me.loading = true;
      axios.get("api/Agencias/Listar", {
        params: {
          buscar: me.buscarAgencia == "" ? "-" : me.buscarAgencia,
          idregion: me.idregion,
        }
      })
        .then(function (response) {
          //console.log(response);
          me.agencias = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    select() {
      let me = this;

      var regionesArray = [];
      axios.get("api/Regions/Select")
        .then(function (response) {
          regionesArray = response.data;
          regionesArray.map(function (x) {
            me.regiones.push({ text: x.dscregion + ' > ' + x.dscterritorio, value: x.idregion });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    // Seleccionar usuario por busqueda
    selectUsuario(id) {
      let me = this;

      var usuariosArray = [];

      if (id) {
        axios.get("api/Usuarios/SelectUsuarios/" + id)
          .then(function (response) {
            usuariosArray = response.data;
            usuariosArray.map(function (x) {
              me.usuarios.push({
                text: x.nomjbs,
                value: x.idusuario,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Usuarios/SelectUsuariosFiltro", {
          params: {
            buscarAgencia: me.buscarUsuario == "" ? "-" : me.buscarUsuario == null ? "-" : me.buscarUsuario,
            idregion: me.idregion
          },
        })
          .then(function (response) {
            usuariosArray = response.data;
            usuariosArray.map(function (x) {
              me.usuarios.push({
                text: x.nomjbs,
                value: x.idusuario,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    /* Editar y guardar agencia */
    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        // Editar
        axios.put("api/Agencias/Actualizar", {
          idagencia: me.idagencia,
          idregion: me.idregion,
          idusuario: me.idusuario,
          codigo: me.codigo,
          dscagencia: me.dscagencia,
          direccion: me.direccion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        // Guardar
        axios.post("api/Agencias/Crear", {
          idusuario: me.idusuario,
          idregion: me.idregion,
          idusuario: me.idusuario,
          codigo: me.codigo,
          dscagencia: me.dscagencia,
          direccion: me.direccion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    /* Eliminar agencia */
    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la agencia " + item.dscagencia + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Agencias/Eliminar/" + item.idagencia)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    /* Editar agencia item */
    editItem(item) {
      this.idagencia = item.idagencia;
      this.idregion = item.idregion;
      this.idusuario = item.idusuario;
      this.selectUsuario(item.idusuario);
      this.codigo = item.codigo;
      this.dscagencia = item.dscagencia;
      this.direccion = item.direccion;

      this.editedIndex = 1;
      this.dialog = true;
    },

    /* Cerrar ventana agencia */
    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idagencia = "";
      //this.idregion = parseInt(this.usuario.idregion); // No mover
      this.idusuario = "";
      this.usuarios = [];
      this.dscagencia = "";
      this.codigo = "";
      this.direccion = "";

      this.editedIndex = -1;
    },
  },
};
</script>