import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#043D5D',
        secondary: '#98676D',
        accent: '#82B1FF',
        error: '#EF5C54',
        info: '#59A9C2',
        success: '#499642',
        warning: '#FFC107',

        bluedarken: '#1976D2',
        greendarken: '#388E3C',
        yellowdarken: '#FBC02D',
        orangedarken: '#F57C00',
        reddarken: '#D32F2F'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
});
